@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap');

.placeholder-pl-none > input::placeholder {
  padding-left: 0 !important;
}

hr.dotted {
  border:none;
  border-top:1px dashed #76BEAB;
  color:#fff;
  background-color:#fff;
  height:1px;
}
/* 
.products-slider > .slick-list {
  height: 210px !important;
}

.products-slider > .slick-track {
  height: 210px !important;
} */

.slick-list{
  overflow: visible;
}

.bg-custom-first{
  background: linear-gradient(to right, #fff 0%, #fff 50%, rgba(255, 255, 255,0) 51%, rgba(255, 255, 255,0) 100%);
}
.bg-custom-last{
  background: linear-gradient(to right, rgba(255, 255, 255,0) 0%, rgba(255, 255, 255,0) 50%, #fff 51%, #fff 100%);
}
